.sva {
  --main-color: #91919b;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  border: solid 1px #ccc;
  align-items: center;
  font-weight: bold;
  max-width: 380px;
}
.sva__number {
  position: relative;
  font-size: 1.875rem;
  color: var(--main-color);
  width: 55%;
  padding-inline: var(--space-4);
  line-height: normal;
}
.sva__number::after {
  left: 100%;
  top: 50%;
  margin-top: -8px;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 8px solid transparent;
  border-left-color: #fff;
}
.sva__legend {
  text-align: left;
  width: 43%;
  background-color: var(--main-color);
  font-size: 0.9375rem;
  color: #fff;
}
.sva__price {
  padding-inline: calc(3 * var(--space-4)) var(--space-4);
  padding-block: var(--space-4);
  line-height: 1.2;
}
@media (max-width: 479px) {
  .sva {
    flex-direction: column;
    max-width: 204px;
  }
  .sva .sva__legend,
  .sva .sva__number {
    width: 100%;
  }
  .sva .sva__number::after {
    left: 6px;
    bottom: -16px;
    top: auto;
    margin-top: 0;
    border-left-color: transparent;
    border-top-color: #fff;
  }
  .sva .sva__price {
    padding-inline: var(--space-20);
    max-width: 150px;
  }
}
.sva--free {
  --main-color: #78b41e;
}
.sva--charged {
  --main-color: #a50f78;
}